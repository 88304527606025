import React, { Fragment, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Layout, Menu, Popover, Badge } from 'antd';
import { CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons';
import Image from 'helpers/Image';
import "./style.css";
import { useContextHelper } from 'helpers/ContextHelper';
import PrivateImage from 'helpers/PrivateImage';
import { fileViewBaseUrl } from 'helpers/constants/constants';


const { Sider } = Layout;

const Sidebar = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const { profile } = useContextHelper();
  const [collapsed, setCollapsed] = useState(false);

  const REACT_APP_SITE_NAME= process.env.REACT_APP_SITE_NAME;
  const sitename="/"+REACT_APP_SITE_NAME;

  const selectedKey = (path) => {
    //dashboard
    if (path === sitename+"/dashboard") {
      return "dashboard"
    }
    //user
    else if (path === sitename+"/user-management") {
      return "userManagement"
    }
    //department
    else if (path === sitename+"/department-management") {
      return "userManagement"
    }
    //role
    else if (path === sitename+"/role") {
      return "userManagement"
    }
    //holiday
    else if (path === sitename+"/holiday") {
      return "userManagement"
    }
    //notification
    else if (path === sitename+"/notification-management") {
      return "notification"
    }
    //machinelist
    else if (path === sitename+"/machine-list") {
      return "Machine"
    }
    else if (path === sitename+"/machine-list/sub-machine") {
      return "Machine"
    }
    //machineinspection
    else if (path === sitename+"/machine-inspection") {
      return "MachineInspection"
    }
    else if (path === sitename+"/inspection-records") {
      return "MachineInspection"
    }
    else if (path === sitename+"/inspection-pending") {
      return "MachineInspection"
    }
    else if (path === sitename+"/machine-inspection/inspection-list") {
      return "MachineInspection"
    }
    else if (path === sitename+"/machine-list/sub-table") {
      return "MachineInspection"
    }
    else if (path === sitename+"/machine-inspection/machine-system") {
      return "MachineInspection"
    }
    //chart
    else if (path === sitename+"/chart") {
      return "Chart"
    }
    //scorecard
    else if (path === sitename+"/scorecard") {
      return "Scorecard"
    }
    else if (path === sitename+"/individualcard") {
      return "Scorecard"
    }
    //activitylog
    else if (path === sitename+'/activitylog') {
      return "Activitylog"
    }
    else if (path === sitename+'/version-management') {
      return "version"
    }
  }

  const handleClick = (val) => {
    setCurrentMent(val.key);
    if (val?.key === "dashboard") {
      navigate(sitename+"/dashboard");
    }
    else if (val?.key === 'userManagement') {
      navigate(sitename+"/user-management");
    }
    else if (val?.key === "notification") {
      navigate(sitename+"/notification-management");
    }
    else if (val?.key === 'Machine') {
      navigate(sitename+"/machine-list")
    }
    else if (val?.key === 'MachineInspection') {
      navigate(profile?.isHomePageView === true ? sitename+"/machine-inspection" : sitename+"/machine-inspection/inspection-list")
    }
    else if (val?.key === 'Chart') {
      navigate(sitename+"/chart")
    }
    else if (val?.key === 'Scorecard') {
      navigate(sitename+"/scorecard")
    }
    else if (val?.key === 'Scorecard') {
      navigate(sitename+"/individualcard")
    }
    else if (val?.key === 'Activitylog') {
      navigate(sitename+"/activitylog")
    }
    else if (val?.key === 'version') {
      navigate(sitename+"/version-management")
    }

  }
  const [currentMenu, setCurrentMent] = useState(selectedKey(location?.pathname));


  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }


  const menu_items = () => {
    const menu = [];
    const settingChild = [];
    const addItemIfPermitted = (name, key, apiKey, icon) => {
      const permission = profile?.permissionDtoList?.find(p => p.permissionName === apiKey);
      if (permission && permission.isView) {
        menu.push(getItem(name, key, icon));
      }
    };
    const addChildIfPermitted = (name, key, apiKey, icon) => {
      const permission = profile?.permissionDtoList?.find(p => p.permissionName === apiKey);
      if (permission && permission.isView) {
        settingChild.push(getItem(name, key, icon));
      }
    };


    addItemIfPermitted("Machine Inspection", "MachineInspection", 'Machine Inspection',
      <Image name={currentMenu === "MachineInspection" ? "whiteMIS.svg" : "blueMIS.svg"} className="sidebar-tab-icon" />
    );

    addItemIfPermitted("Dashboard", 'dashboard', "Dashboard",
      <Image name={currentMenu === "dashboard" ? "dashboard-white.svg" : "dashboard.svg"} className="sidebar-tab-icon" />
    );

    addItemIfPermitted("Scorecard", 'Scorecard', "Scorecard",
      <Image name={currentMenu === "Scorecard" ? "scorecard_white.png" : "scorecard.png"} className="sidebar-tab-icon" />
    );

    addItemIfPermitted("Trend", "Chart", 'Chart',
      <Image name={currentMenu === "Chart" ? "chart-white.png" : "chart.png"} className="sidebar-tab-icon" />
    );
    //setting child
    addChildIfPermitted("Machine Management", "Machine", 'Machine Management',
      <Image name={currentMenu === "Machine" ? "MI-white.png" : "Mi-blue.png"} className="sidebar-tab-icon" />
    );

    addChildIfPermitted("User Management", "userManagement", 'User',
      <Image name={currentMenu === "userManagement" ? "dashboard-user-white.svg" : "dashboard-user.svg"} className="sidebar-tab-icon" />
    );

    addChildIfPermitted("Notification Management", "notification", 'Notification Management',
      <Image name={currentMenu === "notification" ? "notification-white.svg" : "notification-blue.svg"} className="sidebar-tab-icon" />
    );

    addChildIfPermitted("Activity Log", "Activitylog", "Activity Log",
      <Image name={currentMenu === "Activitylog" ? "log_white.png" : "log_blue.png"} className="sidebar-tab-icon" />
    );
    // addChildIfPermitted("Template Version", "version", "TemplateVersion",
    //   <Image name={currentMenu === "version" ? "certificate-white.svg" : "certificate.svg"} className="sidebar-tab-icon" />
    // );
    const setting = getItem("Settings", "Settings",
      <Image name={currentMenu === "Settings" ? "setting-white.svg" : "setting-blue.svg"} className="sidebar-tab-icon" />,
      settingChild

    )
    if (settingChild.length > 0) {

      menu.push(setting)
    }
    return menu;
  };


  const items = menu_items();

  const profileNav = () => {
    navigate(sitename+"/profile");
  }
  const logout = () => {
    localStorage.clear();
    navigate(sitename+"/login");
  }

  const content = (
    <div className='pop-hover-content'>
      <div className='pop-profile' onClick={profileNav}>
        <p>Profile</p>
        {/* <Image name="profile-right-arrow.svg" /> */}
      </div>
      <hr />
      <div className='pop-logout' onClick={logout} >
        <p>Logout</p>
      </div>
    </div>
  );
  const close = (val) => {
    setCollapsed(!collapsed)

    var element = document.querySelector('.ant-ribbon.ant-ribbon-placement-end');
    if (element) {
      if (val === true) {
        element.style.right = '-20px';
      }
      else {
        element.style.right = '-8px';
      }
    }

  }


  return (
    <Fragment>
      <Badge.Ribbon text={
        !collapsed ? <CaretLeftOutlined onClick={() => close(true)} /> : <CaretRightOutlined onClick={() => close(false)} />
      }  >


        <Sider trigger={null} className={collapsed ? "sidebar-small" : "sidebar-big"} collapsible collapsed={collapsed}
        >
          <div className={collapsed ? "sidebar-logo-small txt-center" : "sidebar-logo-big txt-center"}>
            {collapsed ? <Image name="small-logo.png" className={collapsed ? "small-logo" : "logo"} onClick={() => close(false)} /> :
              <Link to="/">
                <div className="login-image slider">
                  <Image name="image1.png" className="login_logo" />
                  <p className="login-text" id="spc">Machine</p>
                  <p className="login-text1">Inspection</p>
                </div>
              </Link>}

          </div>

          <Menu
            theme="dark"
            mode="inline"
            className='sidebar-menu-item header_height'
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['settings']}
            onClick={handleClick}
            selectedKeys={[selectedKey(location?.pathname)]}
            items={items}
          >
            {items.map((item, a) => (
              !item.children ? (
                <Menu.Item key={item.key} icon={item.icon} className='animated-menu' style={{ animationDelay: `${a * 0.2}s` }}>
                  <Link to={sitename+"/dashboard"}>
                    {item.label}
                  </Link>
                </Menu.Item>
              ) : (
                <Menu.SubMenu key={item.key} icon={item.icon} title={item.label} className='animated-menu' style={{ animationDelay: `${a * 0.5}s` }}>
                  {item.children.map((child, e) => (
                    !child.children ? (
                      <Menu.Item key={child.key} icon={child.icon} className='animated-sub-menu' style={{ animationDelay: `${e * 0.5}s` }}>
                        <Link to={sitename+`/settings/${child.key}`}>
                          {child.label}
                        </Link>
                      </Menu.Item>
                    ) : (
                      <Menu.SubMenu key={child.key} icon={child.icon} title={child.label} className='animated-sub-menu' style={{ animationDelay: `${e * 0.5}s` }}>
                        {child.children.map((subChild, f) => (
                          <Menu.Item key={subChild.key} icon={subChild.icon} className='animated-sub-sub-menu' style={{ animationDelay: `${f * 0.5}s` }}>
                            <Link to={sitename+`/settings/${subChild.key}`}>
                              {subChild.label}
                            </Link>
                          </Menu.Item>
                        ))}
                      </Menu.SubMenu>
                    )
                  ))}
                </Menu.SubMenu>
              )
            ))}
          </Menu>

          <br /><br /><br /><br />
          <div className="version-sidebar">
              <p>v : {localStorage.getItem("version") || 0.0}</p>
            </div>
          {/* <><p>v 1.1.2</p></> */}
          <div className={collapsed ? "sidebar-Footer-small" : "sidebar-Footer-big"}>
            <div className='profile-details'>
            <Popover content={content} placement='top'>
              <Row  style={{alignItems: 'center'}}>
                <Col md={6}>
                  {profile?.profileImageUrl ?
                    <PrivateImage privateUrl={`${fileViewBaseUrl}${encodeURIComponent(profile?.profileImageUrl)}`} className="prof-img" shape="prof-img" />
                    : <Image name="profile-pic.png" className="prof-img" />}
                </Col>
                <Col md={15}>
                  <p>{profile?.userName}</p>
                  <p>{profile?.roleName}</p>
                </Col>
                <Col md={1}>
                  
                    <span className='profile-button'>
                      <Image className="dote" name="dote.svg" />
                    </span>
                 
                </Col>
              </Row> </Popover>
            </div>
          </div>
        </Sider>
      </Badge.Ribbon>
    </Fragment >
  )
}

export default Sidebar
