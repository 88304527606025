import { message } from "antd";
import axios from "axios";

const SECONDS = 30;
const MILLISECONDS = 1000;
const TIMEOUT = SECONDS * MILLISECONDS;
const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;

const PublicNetworkClient = axios.create({
    baseURL: REACT_APP_BASE_API,
    timeout: TIMEOUT,
    headers: {
        "content-type": "application/json",
    }
});

PublicNetworkClient.interceptors.request.use(function (config) {
    return config;
});

PublicNetworkClient.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error?.response?.data?.statusCode === 400) {
            message.error(error?.response?.data?.message);
        } else if (error.response.data) {
            message.error(error?.response?.data?.message);
        }
        return Promise.reject(error);
    }
);

export default PublicNetworkClient;