import PrivateNetworkClient from "helpers/constants/privateNetwork";
import SpcNetworkClient from "helpers/constants/spcNetwork";

const ProfileDetails = async (userId) => {
    if (localStorage.getItem('token') === null) {
        return null;
    }
    if(userId === null){
        return null;
    }
    const { data } = await PrivateNetworkClient.get(`/Role/RolePermission`);
    return data;
};
const Authentication = async (formData) => {
    const {data} = await SpcNetworkClient.post("/Password/Authentication", formData );
    return data;
}
const SaveLogin = async (formData) => {
    const { data } = await PrivateNetworkClient.post("User/SaveLogIn", formData);
    return data;
}
export {
    ProfileDetails,Authentication,SaveLogin
};