import { useMutation, useQuery } from "react-query"
import * as profile from "store/services/profile/profile"

export const useProfileDetails = (userId) => {
    return useQuery(["profileDetails", userId], () => profile.ProfileDetails(userId), {
        // enabled: false,
        // retry: false,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false
    })
}
export const useAuthentication = () => {
    return useMutation((formData) => profile.Authentication(formData));
}
export const useSaveLogin = () => {
    return useMutation((formData) => profile.SaveLogin(formData));
}