import { useMutation } from "react-query";
import * as version from "store/services/version/version";

export const useGetVersion = () => {
    return useMutation((formData) => version.getversion(formData));
};
export const useUpdateVersion = () => {
    return useMutation((formData) => version.updateversion(formData));
};
export const useGetApplicationVersion = () => {
    return useMutation((formData) => version.getApplicationVersion(formData));
};