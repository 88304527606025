import { message } from "antd";
import axios from "axios";
import globalRouter from "globalRouter.ts";


const REACT_APP_SPC_API = process.env.REACT_APP_SPC_API;
const REACT_APP_SITE_NAME= process.env.REACT_APP_SITE_NAME;
const sitename="/"+REACT_APP_SITE_NAME;

const SECONDS = 30;
const MILLISECONDS = 1000;
const TIMEOUT = SECONDS * MILLISECONDS;


const SpcNetworkClient = axios.create({
    baseURL: REACT_APP_SPC_API,
    timeout: TIMEOUT,
    headers: {
        "content-type": "application/json",
    }
});

SpcNetworkClient.interceptors.request.use(function (config) {
    return config;
});
SpcNetworkClient.interceptors.request.use(config => {
    const jwtToken = localStorage.getItem("token")
    if (jwtToken) {
        config.headers["authorization"] = 'Bearer ' + jwtToken
    }
    if (!jwtToken && !config.headers["public-request"]) {
    }
    return config
}, error => {
    return Promise.reject(error)
})
SpcNetworkClient.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error?.response?.status === 400) {
            message.error(error?.response?.data);
        } else if (error?.response?.status === 401) {

            if (globalRouter?.navigate) {
                globalRouter?.navigate("", { state: { message: "Session Time Out! Please ReLogin." } });
              }
            message.error(error?.response?.statusText);

        } else if (error.response.data) {
            message.error(error?.response?.data?.message);
        }

        return Promise.reject(error);
    }
);

export default SpcNetworkClient;