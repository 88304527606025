import React, { Fragment } from 'react'
import Header from './utils/auth/header';
import Footer from './utils/auth/footer';
import { Outlet } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { AUTH } from 'helpers/utility/Global';

const AuthLayouts = () => {
    const auth = localStorage.getItem("userId") ? true : false;
    const sitename = process.env.REACT_APP_SITE_NAME;

    return auth ? <Navigate to={`/${sitename}/dashboard`} /> :
        <Fragment>
            {/* <Header /> */}
            <Outlet />
            {/* <Footer /> */}
        </Fragment>
}
export default AuthLayouts