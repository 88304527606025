import PrivateNetworkClient from "helpers/constants/privateNetwork";
import PublicNetworkClient from "helpers/constants/publicNetwork";

const url="/TemplateVersion/"

const getversion = async (formData) => {
    const { data } = await PrivateNetworkClient.post(url+`GetTemplateVersion`, formData);
    return data;
};
const updateversion = async (formData) => {
    const { data } = await PrivateNetworkClient.put(url+`UpdateTemplateVersion`, formData);
    return data;
};
const getApplicationVersion = async (formData) => {
    const { data } = await PublicNetworkClient.post(`Common/getVersion`, formData);
    return data;
};
export {
    getversion,updateversion,getApplicationVersion
}
