import React, { lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation, useSearchParams } from "react-router-dom";
// import NotFound from "views/shared-component/404";
import Image from "helpers/Image";

// Layouts
import AuthLayouts from "layouts/AuthLayouts";
import AppLayout from "layouts/CommonLayout";
import { useProfileDetails, useAuthentication, useSaveLogin } from "store/hooks/profile/profile";
import { useGetApplicationVersion } from "store/hooks/version/version";
import { useContextHelper } from "helpers/ContextHelper";
import globalRouter from "globalRouter.ts";
import { message } from "antd";


// Report & Performance Routes
const Dashboard = lazy(() => import("./views/app/dashboard/index"));

// System Configuration Routes
const UserManagement = lazy(() => import("./views/app/userAndDepartment/user"));
const DepartmentManagement = lazy(() => import("./views/app/userAndDepartment/department"));
// const TemplateVersion = lazy(() => import("./views/app/templateVersion"));
const Role = lazy(() => import("./views/app/userAndDepartment/role/role"))
const NotificationManagement = lazy(() => import("./views/app/notificationManagement/index"));
const Holiday = lazy(() => import("./views/app/userAndDepartment/holiday"))
const Shifts = lazy(() => import("./views/app/userAndDepartment/shift"))

const MachineList = lazy(() => import("./views/app/machineList/machine"));
const SubMachineList = lazy(() => import("./views/app/machineList/subMachine"));
const SubArchieve = lazy(() => import("./views/app/machineList/Archieve"));

const InspectionRecord = lazy(() => import("./views/app/MachineInspection/machineList/components/record"))
const InspectionPending = lazy(() => import("./views/app/MachineInspection/machineList/components/pending"))
const MachineInspection = lazy(() => import("./views/app/MachineInspection/machineList/index"))
const MachineSystem = lazy(() => import("./views/app/MachineInspection/machineSystem/index"))
const Subtable = lazy(() => import("./views/app/MachineInspection/machineList/components/qrList"))
const PlayerView = lazy(() => import("./views/app/MachineInspection/machineList/components/playerView"))

const Chart = lazy(() => import("../src/views/app/chart/chart"));
const Scorecard = lazy(() => import("../src/views/app/scorecard/scorecard"))
const IndividualCard = lazy(() => import("../src/views/app/scorecard/individual"))
const Activitylog = lazy(() => import("./views/app/activityLogs/activitylog"))
const Profile = lazy(() => import("../src/views/app/profile/profile"))

const QRCode = lazy(() => import("./views/app/QRcodescanner/machineList"));
const MachineSubmit = lazy(() => import("./views/app/QRcodescanner/machineList/machineSubmit"));

const Register = lazy(() => import("../src/views/auth/register"))
const Login = lazy(() => import("../src/views/auth/login"))
const ForgotPassword = lazy(() => import("./views/auth/forgotPassword/index"));
const SetPassword = lazy(() => import("./views/auth/forgotPassword/setPassword"));

function App() {
  // client
  // REACT_APP_BASE_API=http://spl-vmssipspc04:5000

  // local
  // REACT_APP_BASE_API=https://miapi.corpfield.com

  const sitename = process.env.REACT_APP_SITE_NAME;
  const navigate = useNavigate();
  let location = useLocation();
  globalRouter.navigate = navigate;
  const [searchParams] = useSearchParams();
  const [backendversion, setVersion] = useState(0);
  const auth = searchParams.get("auth_code");
  const id = localStorage.getItem('userId');
  const { setProfile } = useContextHelper();
  const { data: profileData, refetch } = useProfileDetails(id);
  const { mutate: Authentication, isLoading: authloading } = useAuthentication();
  const { mutate: AuthLogin, isLoading: loginloading } = useSaveLogin();
  const { mutate: getVersion, isLoading: versionloading } = useGetApplicationVersion();



  const fetchVersion = () => {
    getVersion('', {
      onSuccess: (item) => {
        console.log(item?.data);
        localStorage.setItem("version", item?.data?.version)
        localStorage.setItem("copyRight", item?.data?.copyRight)
      }
    })
  }

  useEffect(() => {
    fetchVersion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(() => {
    if (profileData) {
      setProfile(profileData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData])

  useEffect(() => {
    if (id && id !== null) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  //Logout function Start
  const [logout, setLogout] = useState(false);
  const timeout = 900000
  useEffect(() => {
    let timer;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => setLogout(true), timeout);
    };

    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];

    const resetEvents = () => {
      events.forEach(event => {
        window.addEventListener(event, resetTimer);
      });
    };

    resetTimer();
    resetEvents();

    return () => {
      clearTimeout(timer);
      events.forEach(event => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [timeout]);


  useEffect(() => {
    console.log(auth);

    if (auth !== null) {

      const payload2 = {
        'token': auth
      }
      Authentication(payload2, {
        onSuccess: (data) => {
          if (data?.status != "true") {


            // localStorage?.setItem("isManagementView", data?.isManagementView)
            // navigate("/"+sitename+"/user-not-found")
            return;
          }
          else {
            localStorage.setItem("token", data?.token)
            // if (data?.isManagementView === true) {
            //   localStorage.setItem("Departmentname", "");
            // } else {
            //   if(data?.departmentList.length !== 0){
            //     localStorage.setItem("Departmentname", data?.departmentList[0]);
            //   }else{
            //     localStorage.setItem("Departmentname", "");
            //   }
            // }
            // localStorage?.setItem("isManagementView", data?.isManagementView)
            // login
            AuthLogin(data?.token, {
              onSuccess: (res) => {
                console.log(res);
                if (res?.statusCode === 200) {
                  console.log("localdataset", res);

                  localStorage.setItem("userId", data?.token)
                  // localStorage?.setItem("Email", data?.customerEmail)
                  refetch()
                  // navigate("/dasboard")
                }
                else {
                  // navigate("/"+sitename+"/user-not-found")
                  return;
                }
              }, onError: (error) => {
                // navigate("/"+sitename+"/user-not-found")
              }
            })
            // end login

          }
        }, onError: (error) => {
          // navigate("/"+sitename+"/user-not-found")
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    if (logout) {

      if (location?.pathname !== sitename + "/machine-inspection/machine-system") {
        message.warning('Session Time Out! Please ReLogin.');
        localStorage?.clear();
        window.location.href = '/';
      }

      console.log('User has been logged out due to inactivity');
    }
  }, [logout]);
  //Logout function end
  return (
    <Suspense fallback={<div className="loader-img"><Image name="mainLoader2.gif" /></div>}>
      <Routes>
        <Route element={<AuthLayouts />}>
          <Route exact path={`/`} element={<Login />} />
          <Route exact path={`/${sitename}/`} element={<Login />} />
          <Route path={`/${sitename}/Register`} element={<Register />} />
          <Route path={`/${sitename}/login`} element={<Login />} />
          <Route path={`/${sitename}/forgot-password`} element={<ForgotPassword />} />
          <Route path={`/${sitename}/set-password`} element={<SetPassword />} />

        </Route>
      </Routes>
      <Routes>
        <Route element={<AppLayout initialRefetch={refetch} />}>
          {/* <Route path={`/${sitename}/`} element={<Dashboard />} /> */}
          <Route path={`/${sitename}/dashboard`} element={<Dashboard />} />
          <Route path={`/${sitename}/user-management`} element={<UserManagement />} />
          <Route path={`/${sitename}/department-management`} element={<DepartmentManagement />} />
          {/* <Route path={`/${sitename}/version-management`} element={<TemplateVersion />} /> */}
          <Route path={`/${sitename}/role`} element={<Role />} />
          <Route path={`/${sitename}/notification-management`} element={<NotificationManagement />} />
          <Route path={`/${sitename}/machine-list`} element={<MachineList />} />
          <Route path={`/${sitename}/machine-list/sub-machine`} element={<SubMachineList />} />
          <Route path={`/${sitename}/machine-list/archieve`} element={<SubArchieve />} />
          <Route path={`/${sitename}/machine-list/sub-table`} element={<Subtable />} />
          <Route path={`/${sitename}/machine-list/player`} element={<PlayerView />} />
          <Route path={`/${sitename}/machine-inspection`} element={<MachineInspection />} />
          <Route path={`/${sitename}/inspection-records`} element={<InspectionRecord />} />
          <Route path={`/${sitename}/inspection-pending`} element={<InspectionPending />} />
          <Route path={`/${sitename}/machine-inspection/machine-system`} element={<MachineSystem />} />
          <Route path={`/${sitename}/chart`} element={<Chart />} />
          <Route path={`/${sitename}/scorecard`} element={<Scorecard />} />
          <Route path={`/${sitename}/individualcard`} element={<IndividualCard />} />
          <Route path={`/${sitename}/activitylog`} element={<Activitylog />} />
          <Route path={`/${sitename}/profile`} element={<Profile />} />
          <Route path={`/${sitename}/machine-inspection/inspection-list`} element={<QRCode />} />
          <Route path={`/${sitename}/machine-inspection/submit-list`} element={<MachineSubmit />} />
          <Route path={`/${sitename}/holiday`} element={<Holiday />} />
          <Route path={`/${sitename}/shift`} element={<Shifts />} />
        </Route>
      </Routes>

    </Suspense>
  );
}

export default App;

